<template>
    <section class="side">
        <div class="list_side">
            <div v-for="(item,i) in sideData" :key="i" class="side_cont">
                <div :class="item.type==1?'ti_ac_':item.type==2?'ti_ac':''" class="ti_">{{item.title}}</div>
                <!-- <img :class="sideData&&sideData.length<=3?'imgshow':''" v-if="i<sideData.length-1" src="" alt=""> -->
                  <div  v-if="i<sideData.length-1" class="img_" :class="sideData&&sideData.length<=3?'imgshow':''">
                    <span class="iconfont" :class="item.type==1?'font_ac':'font'" style="font-size:29px;">&#xe6ac;</span>
                  </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
  name: "ProgressBar",
  props: {
    sideData: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
.side {
    width: 100%;
  .list_side {
    display: flex;
    align-items: center;
    justify-content: center;
    .side_cont {
      display: flex;
      align-items: center;
      .ti_ {
        font-size: 20px;
        color: #8691a8;
        margin-right: 32px;
      }
      .ti_ac {
        font-size: 20px;
        color: #ce9b63;
        margin-right: 32px;
        font-weight: bold;
      }
      .ti_ac_ {
        font-size: 20px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
        font-weight: 400;
        text-align: CENTER;
        color: #ce9b63;
      }
      .img_ {
        width: 29px;
        height: 29px;
        margin-right: 32px;
        .font_ac {
          color: #ce9b63;
        }
        .font {
          color: #8691a8;
        }
      }

      .imgshow {
        margin-right: 80px;
        margin-left: 70px;
      }
    }
  }
}
</style>
