import request from '@/utils/request'

// 定投列表
export function fixallotPage(data) {

    return request({
        url: '/api/sale/fixallot/fixallotPage',
        method: 'post',
        data
    })
}

// 累计定投
export function statisticFixallot(data) {
    return request({
        url: '/api/sale/fixallot/statisticFixallot',
        method: 'post',
        data
    })
}

// 基金转换

export function conversion(data) {
    return request({
        url: '/api/sale/trade/fund/conversion',
        method: 'post',
        data
    })
}

//可转换基金列表
export function page(data) {
    return request({
        url: '/api/sale/fundCommon/page',
        method: 'post',
        data
    })
}

//查询基金概况
export function baseInfo(data) {
    return request({
        url: '/api/sale/prodDetails/baseInfo',
        method: 'post',
        data
    })
}

// 我的定投计划 统计信息查询（累计定投 定投期数 定投金额）
export function statisticFixReq(data) {
    return request({
        url: '/api/sale/fixallot/statisticFixReq',
        method: 'post',
        data
    })
}
// 我的定投计划列表查询
export function fixReqPage(data) {
    return request({
        url: '/api/sale/fixallot/fixReqPage',
        method: 'post',
        data
    })
}
// 定投信息变更为暂停或终止,fix_state为P、H
export function fixModifyPause(data) {
    return request({
        url: '/api/sale/fixallot/fixModifyPause',
        method: 'post',
        data
    })
}
// 定投信息变更,fix_state为A
export function fixModifyTrade(data) {
    return request({
        url: '/api/sale/fixallot/fixModifyTrade',
        method: 'post',
        data
    })
}